export const colors = {
  black: "#222222",
  white: "#ffffff",
  primary: "#43BFEF",
  primary_highlight: "#00A9EA",
  secondary: "#B5E9F7",
  darkgray: "#7F8F91",
  gray: "#CED4E0",
  lightgray: "#FBFCFE",
  lightgray_highlight: "#F5F6F9",
}