const urls = {
  cv: "https://docs.google.com/document/d/1sCBr95yyMY6BvCKqXtX1yVlFNQf9flk0MEYoq07NyCY/edit?usp=sharing",
  linkedin: "https://www.linkedin.com/in/hye1ee/",
  x: "",
  googlescholar: "https://scholar.google.co.kr/citations?hl=en&user=xX0joEYAAAAJ",
  github: "https://github.com/hye1ee",


}

export default urls;